import React from "react";

export default function GridSection({ icon, title, description }) {
  return (
    <div className="gridsection">
      <div className="icon">{icon}</div>
      <span className="title">{title}</span>
      <span className="description">{description}</span>
    </div>
  );
}
