import React from "react";
import FeaturesLeftSection from "../atoms/FeaturesLeftSection";
import FeaturesRightSection from "../atoms/FeaturesRightSection";

export default function FeaturesSection({ largeText, smallText, src, center }) {
  return (
    <div className="featuressection">
      <FeaturesLeftSection largeText={largeText} smallText={smallText} />
      <div className="border" />
      <FeaturesRightSection center={center} src={src} />
    </div>
  );
}
