import React from "react";

export default function Footer() {
  return (
    <div className="footerwrapper">
      <div className="logocontainer">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25ZM25.8789 19.5312C25.8789 16.8346 28.065 14.6484 30.7617 14.6484C33.4584 14.6484 35.6446 16.8346 35.6446 19.5312C35.6446 22.228 33.4584 24.4141 30.7617 24.4141C28.065 24.4141 25.8789 22.228 25.8789 19.5312ZM14.9414 14.6484C12.2447 14.6484 10.0586 16.8346 10.0586 19.5312C10.0586 22.228 12.2447 24.4141 14.9414 24.4141C17.6381 24.4141 19.8242 22.228 19.8242 19.5312C19.8242 16.8346 17.6381 14.6484 14.9414 14.6484Z"
            fill="white"
          />
        </svg>

        <a href="">Privacy Policy</a>
        <a href="">Terms of Service</a>
      </div>

      <div className="transform">
        <span>Fundamentals</span>
        <a href="">Download for mac</a>
        <a href="">Download for windows</a>
        <a href="">Download for mac m1</a>
      </div>

      <div>
        <span>About us</span>
        <a href="">Help center</a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://discord.gg/Zmuf8Z5gKU"
        >
          Join our Discord
        </a>
      </div>

      <div>
        <span>Social media</span>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://twitter.com/theproxytester"
        >
          Twitter
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://mirror.xyz/0x2Df7a20d7bC58590855BF8dcB9B3769778E8a9d7"
        >
          Mirror.xyz
        </a>
      </div>
    </div>
  );
}
