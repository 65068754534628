import React from "react";
import TextTransition from "../atoms/TextTransition";
import { motion } from "framer-motion";

const BLUETEXTS = ["tool", "system"];
const REDTEXTS = ["understand", "dig deep", "create", "feel"];

export default function LargeHeader() {
  const [index, setIndex] = React.useState(0);
  const [timing, setTiming] = React.useState(10);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      timing
    );
    setTiming(3000);

    return () => clearTimeout(intervalId);
  }, [timing]);

  return (
    <motion.div className="largeheader">
      A new{" "}
      <motion.div className={"bluebg"}>
        <TextTransition text={[BLUETEXTS[index % BLUETEXTS.length]]} />
      </motion.div>
      to
      <motion.div className={"redbg"}>
        <TextTransition text={[REDTEXTS[index % REDTEXTS.length]]} />
      </motion.div>
    </motion.div>
  );
}
