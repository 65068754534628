import React from "react";
import Button from "../atoms/Button";

export default function DownloadButtons() {
  const getOs = () => {
    const winOS = ["Win32", "Win64", "Windows"];
    const macOS = ["Mac68K", "MacPPC", "Macintosh", "MacIntel"];
    const macM1 = ["MacAppleSilicon"];

    const userAgent = window.navigator.platform;
    if (winOS.indexOf(userAgent) !== -1) return "win";
    if (macOS.indexOf(userAgent) !== -1) return "mac";
    if (macM1.indexOf(userAgent) !== -1) return "M1";
  };

  return (
    <div className="flex al-ce jc-ce btns">
      <Button
        text={"Download for Windows"}
        active={getOs() === "win"}
        onClick={() => {
          window.location.href =
            "https://get.theproxytester.com/aproxytester-1.0.0+Setup.exe";
        }}
      />

      <Button
        text={"Mac Intel"}
        active={getOs() === "mac"}
        onClick={() => {
          window.location.href =
            "https://get.theproxytester.com/aproxytester-darwin-x64-1.0.0.zip";
        }}
      />

      <Button
        text={"M1"}
        active={getOs() === "M1"}
        onClick={() => {
          window.location.href =
            "https://get.theproxytester.com/aproxytester-darwin-arm64-1.0.0.zip";
        }}
      />
    </div>
  );
}
