import React from "react";

export default function FeaturesLeftSection({ largeText, smallText }) {
  return (
    <div>
      <div className="largetext">{largeText}</div>
      <div className="smalltext">{smallText}</div>
    </div>
  );
}
