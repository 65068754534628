import "./App.scss";
import Header from "./components/atoms/Header";
import SmallHeader from "./components/atoms/SmallHeader";
import LargeHeader from "./components/molecules/LargeHeader";
import DownloadButtons from "./components/molecules/DownloadButtons";
import FeaturesSection from "./components/molecules/FeaturesSection";
import Footer from "./components/molecules/Footer";
import Grid from "./components/molecules/Grid";

function App() {
  return (
    <div className="App">
      <main>
        <SmallHeader />
        <LargeHeader />
        <DownloadButtons />
        <FeaturesSection
          largeText={"10x"}
          smallText={
            <span>
              faster proxy testing
              <br />
              compared to other proxy testers{" "}
            </span>
          }
          src={require("./assets/feat1.png")}
        />
        <FeaturesSection
          largeText={"What"}
          smallText={
            <span>
              understand whats happening behind your
              <br />
              proxies/IPs and get real results
            </span>
          }
          center
          src={require("./assets/feat2.png")}
        />
        <FeaturesSection
          largeText={"4x"}
          smallText={
            <span>
              easier and more in-depth
              <br />
              export options
            </span>
          }
          center
          src={require("./assets/feat3.png")}
        />
        <Header />
        <Grid />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
