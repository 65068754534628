import React from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function TextTransition({ text, ...props }) {
  return (
    <motion.div
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
      >
        {text}
      </motion.div>
    </motion.div>
  );
}
